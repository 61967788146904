<template>
  <div class="ll_content">
    <!--模块1 主页 :style="'height:' + windowHeight + 'px'" -->
    <div class="ll_content_1">
      <!--内容居中-->
      <div class="ll_content_1_2">
        <!--logo、中行动态、联系我们-->
        <div class="ll_content_1_2_1">
          <!--logo-->
          <img
            class="iv_content_1_2_1_child"
            src="../assets/images/ic_pc_2.png"
          />
          <!--占位-->
          <!-- <div style="width: 170px"></div> -->
          <div class="ll_content_1_2_1_child">
            <!--中行动态-->
            <div
              class="ll_content_1_2_1_2"
              @click="clickItem('zhong_hang')"
              style="cursor: pointer"
            >
              <img
                class="iv_content_1_2_1_2"
                src="../assets/images/ic_pc_3.png"
              />
              <MyAnimationSelect class="tv_content_1_2_1_2">
                <a>{{ pageData.zhonghangTitle }}</a>
              </MyAnimationSelect>
            </div>
            <!--占位-->
            <div class="v_content_view_1"></div>
            <!--联系我们-->
            <div class="ll_content_1_2_1_3">
              <img
                style="width: 30px; height: 30px"
                src="../assets/images/ic_pc_4.png"
              />
              <div class="tv_content_1_2_1_3">{{ pageData.lianxiInfo }}</div>
            </div>
          </div>
        </div>
        <!--大标题-->
        <img
          style="width: 686px; height: 196px"
          src="../assets/images/ic_pc_5.png"
        />
        <!--三个按钮组-->
        <div class="ll_content_1_2_2">
          <!--android-->
          <MyDialogCode
            imageType="android"
            :isShowCode="false"
            @click="clickItem('android_download')"
            style="cursor: pointer"
          >
            <div class="ll_content_1_2_2_3">
              <img
                style="width: 139px; height: 139px"
                src="../assets/images/ic_pc_android.png"
              />
              <div class="tv_content_1_2_2_3">{{ pageData.download_1 }}</div>
            </div>
          </MyDialogCode>
          <!--占位-->
          <div style="width: 200px; height: 1px" />
          <!--ios-->
          <MyDialogCode
            imageType="ios"
            :isShowCode="true"
            @click="clickItem('ios_download')"
            style="cursor: pointer"
          >
            <div class="ll_content_1_2_2_3">
              <img
                style="width: 139px; height: 139px"
                src="../assets/images/ic_pc_ios.png"
              />
              <div class="tv_content_1_2_2_3">{{ pageData.download_2 }}</div>
            </div>
          </MyDialogCode>
          <!--占位-->
          <div style="width: 200px; height: 1px" />
          <!--mini-->
          <MyDialogCode
            imageType="mini"
            :isShowCode="false"
            @click="clickItem('mini_download')"
            style="cursor: pointer"
          >
            <div class="ll_content_1_2_2_3">
              <img
                style="width: 139px; height: 139px"
                src="../assets/images/ic_pc_mini.png"
              />
              <div class="tv_content_1_2_2_3">{{ pageData.download_3 }}</div>
            </div>
          </MyDialogCode>
        </div>
        <!--锚点按钮-->
        <img
          class="iv_maodian anim_pc_maodian_true_setting"
          src="../assets/images/ic_pc_start.png"
          @click="clickItem('gongyi')"
        />
      </div>
    </div>
    <!--模块2 公益-->
    <div class="ll_content_2" id="gongyi">
      <!--标题-->
      <div class="tv_common_title_up">
        {{ pageData.gongyiTitle }}
        <div class="line_common_title"></div>
      </div>

      <div class="tv_common_title_down">{{ pageData.gongyiTitleDesc }}</div>
      <!--内容块  :style="`transform:scale(${pcScale.scale});transformOrigin:top center;`"-->
      <div class="ll_content_2_3" ref="isShowOPTRAnim">
        <!---->
        <div
          :class="{
            ll_gongyi_5_1_phone: true,
            anim_opacity_transform_true_setting: isShowOPTRAnim,
          }"
          v-for="item in pageData.gongyiData"
          :key="item.message"
        > 
          <div class="ll_gongyi_5_3_phone">
            <img
              style="width: 101px; height: 101px"
              class="iv_gongyi_5_2_phone"
              :src="item.logo"
            />
          </div>
          <div class="ll_gongyi_6_phone" 
          :style="{ height: 275*(1.9-pcScale.scale) + 'px' }">
            <div class="tv_gongyi_6_1_phone">{{ item.name }}</div>
            <div class="v_gongyi_6_2_phone"></div>
            <div class="tv_gongyi_6_4_phone">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
    <!--模块3 帮扶-->
    <div class="ll_content_3">
      <!--标题-->
      <div class="tv_common_title_up">
        {{ pageData.bangfuTitle }}
        <div class="line_common_title"></div>
      </div>

      <div class="tv_common_title_down">
        {{ pageData.bangfuTitleDesc }}
      </div>
      <!--内容块  :style="`transform:scale(${pcScale.scale});transformOrigin:top center;`"-->
      <div class="ll_content_3_3" ref="isShowRotateAnim">
        <div
          class="ll_content_3_3_1"
          v-for="(item, index) in pageData.bangfuData"
          :key="index"
          
            :style="{ height: 110*(1.9-pcScale.scale) + 'px' }"
        >
          <div
            :class="{
              tv_fupin_6_2_phone: true,
              anim_rotate_true_setting: isShowRotateAnim,
            }"
          >
            {{ item.name }}
          </div>
          <div class="tv_fupin_sanjiao_phone"></div>
          <div class="tv_fupin_6_3_phone" >{{ item.desc }}</div>
        </div>
      </div>
      <!--提示-->
      <div
        style="
          font-size: 16px;
          color: #ffffff;
          font-weight: 300;
          margin-top: 100px;
        "
      >
        {{ pageData.bangfuDesc }}
      </div>
    </div>
    <!--模块4 平台优势-->
    <div class="ll_content_4">
      <!--标题-->
      <div class="tv_common_title_up">
        {{ pageData.pingtaiTitle }}
        <div class="line_common_title"></div>
      </div>

      <div class="tv_common_title_down">
        {{ pageData.pingtaiTitleDesc }}
      </div>
      <!--内容块 :style="`transform:scale(${pcScale.scale});transformOrigin:top center;`"-->
      <div class="ll_content_4_3" ref="isShowHuiTanAnim">
        <div
          :class="{
            ll_content_4_3_1: true,
            anim_huitan_true_setting: isShowHuiTanAnim,
          }"
          v-for="item in pageData.pingtaiData"
          :key="item.message"
          
            :style="{ height: 220*(1.9-pcScale.scale) + 'px' }"
        >
          <img class="iv_youshi_6" :src="item.logo" />
          <div class="tv_youshi_4">{{ item.name }}</div>
          <div class="tv_youshi_5">
            {{ item.desc }}
          </div>
        </div>
      </div>
    </div>
    <!--模块5 公益联盟-->
    <div class="ll_content_5">
      <!--标题-->
      <div class="tv_common_title_up">
        {{ pageData.lianmengTitle }}
        <div class="line_common_title"></div>
      </div>

      <div class="tv_common_title_down">
        {{ pageData.lianmengTitleDesc }}
      </div>

      <!--公益联盟内容列表-->
      <div
        :class="{
          ll_content_5_chlid: true,
        }"
        :style="`transform:scale(${pcScale.scale});transformOrigin:top center;`"
      >
        <div class="ll_common_lianmeng_title">
          {{ pageData.lianmengData_1_title }}
        </div>
        <!--合作联盟数组-->
        <div class="ll_content_5_chlid_arr">
          <div
            class="ll_content_5_item"
            :style="
              'margin-left:20px;' +
              'margin-right:20px;' +
              (index == pageData.lianmengData_1.length - 1
                ? 'background:rgba(255,255,255,0.60);'
                : '')
            "
            v-for="(item, index) in pageData.lianmengData_1"
            :key="index"
          >
            <img style="width: 100%; height: 100%" :src="item.logo_big" />
            <!--前5个样式-->
            <!-- <img
              style="width: 88px; height: 88px"
              :src="item.logo"
              v-if="index != pageData.lianmengData_1.length - 1"
            />
            <div
              class="tv_content_5_item"
              v-if="index != pageData.lianmengData_1.length - 1"
            >
              {{ item.name }}
            </div> -->
            <!--最后一个样式-->
            <!-- <img
              style="width: 68px; height: 8px"
              :src="item.logo"
              v-if="index == pageData.lianmengData_1.length - 1"
            /> -->
          </div>
        </div>

        <div class="ll_common_lianmeng_title" style="margin-top: 100px">
          {{ pageData.lianmengData_2_title }}
        </div>
        <!--合作联盟数组-->
        <div class="ll_content_5_chlid_arr">
          <div
            class="ll_content_5_item"
            :style="
              'margin-left:20px;' +
              'margin-right:20px;' +
              (index == pageData.lianmengData_2.length - 1
                ? 'background:rgba(255,255,255,0.60);'
                : '')
            "
            v-for="(item, index) in pageData.lianmengData_2"
            :key="index"
          >
            <img style="width: 100%; height: 100%" :src="item.logo_big" />
            <!--前5个样式-->
            <!-- <img
              style="width: 88px; height: 88px"
              :src="item.logo"
              v-if="index != pageData.lianmengData_2.length - 1"
            />
            <div
              class="tv_content_5_item"
              v-if="index != pageData.lianmengData_2.length - 1"
            >
              {{ item.name }}
            </div> -->
            <!--最后一个样式-->
            <!-- <img
              style="width: 68px; height: 8px"
              :src="item.logo"
              v-if="index == pageData.lianmengData_2.length - 1"
            /> -->
          </div>
        </div>
      </div>
    </div>
    <!--模块6 关于我们-->
    <div class="ll_content_6">
      <!--标题-->
      <div class="tv_common_title_up">
        {{ pageData.aboutTitle }}
        <div class="line_common_title"></div>
      </div>

      <div class="tv_common_title_down">
        {{ pageData.aboutTitleDesc }}
      </div>
      <!--关于我们内容-->
      <div class="ll_content_6_child">
        <img class="iv_content_6_child" src="../assets/images/ic_shouchi.jpg" />
        <div class="tv_content_6_child">{{ pageData.aobutDesc }}</div>
      </div>
    </div>
    <!--底部协议-->
    <div class="ll_xieyi_1">
      <div class="ll_xieyi_4">
        <MyAnimationSelect
          class="tv_xieyi_2"
          @click="clickItem('agreement', 310)"
          style="cursor: pointer"
        >
          {{ pageData.agreement_1 }}
        </MyAnimationSelect>
        <MyAnimationSelect
          class="tv_xieyi_2"
          @click="clickItem('agreement', 100)"
          style="cursor: pointer"
        >
          {{ pageData.agreement_2 }}
        </MyAnimationSelect>
      </div>
      <div class="ll_xieyi_4">
        <div class="tv_xieyi_5">{{ pageData.company_1 }}</div>
        <MyAnimationSelect
          class="tv_xieyi_6"
          @click="clickItem('beian_icp')"
          style="cursor: pointer"
        >
          {{ pageData.ICP_info }}
        </MyAnimationSelect>
        <MyAnimationSelect
          class="ll_xieyi_7"
          @click="clickItem('beian_gov')"
          style="cursor: pointer"
        >
          <img class="iv_xieyi_8" src="../assets/images/gongwanganbeihao.png" />
          <div class="tv_xieyi_9">{{ pageData.beian_info }}</div>
        </MyAnimationSelect>
        <div class="tv_xieyi_5">{{ pageData.bottom_info }}</div>
      </div>
    </div>
  </div>
</template>

<script >
//缩放
import MyAnimationSelect from "./my-animation-select.vue";
//弹窗
import MyDialogCode from "./my-dialog-code.vue";
export default {
  name: "MyPagePc",
  props: ["pageData", "pcScale"],
  data() {
    return {
      isShowRotateAnim: false, //显示回滚动画
      isShowOPTRAnim: false, //淡入＋位移动画
      isShowHuiTanAnim: false, //回弹动画
    };
  },
  components: {
    MyDialogCode,
    MyAnimationSelect,
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); //页面滚动监听
  },
  methods: {
    showAnim(refName) {
      if (
        !this[refName] &&
        this.$refs[refName] &&
        this.$refs[refName].getBoundingClientRect().y < window.innerHeight
      ) {
        //显示动画
        this[refName] = true;
      }
    },
    handleScroll() {
      //根据页面滚动状态，对页面的动画进行相应的显示操作
      //淡入+位移
      this.showAnim("isShowOPTRAnim");
      //帮扶数据左右旋转动画 业务不想要这效果
      // this.showAnim("isShowRotateAnim");
      //回弹动画
      this.showAnim("isShowHuiTanAnim");

      // this.$refs.refBangfu.getBoundingClientRect()

      // for (var i = 0; i < this.animArr.length; i++) {
      //   var item = this.animArr[i];
      //   if (this.$refs[item.name]) {
      //     //存在实例
      //     if (
      //       this.$refs[item.name].getBoundingClientRect().y < window.innerHeight
      //     ) {
      //       this.$refs[item.name].classList.add(item.show);
      //     } else {
      //       // that.$refs[item.name].classList.remove(item.show);
      //     }
      //   }
      // }
    },
    clickItem(type, value) {
      this.$emit("appOpen", type, value);
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  text-decoration: none;
}
p:hover {
  text-decoration: underline; /* 下划线 */
  text-decoration-color: #ffffff; /* 下划线颜色 */
  text-decoration-style: dotted; /* 下划线样式 */
  text-decoration-thickness: 2px; /* 下划线粗细 */
}
/**pc端 上下移动动画 */
.anim_pc_maodian_true_setting {
  animation: shake 1.2s infinite ease-in-out;
  animation-name: anim_pc_maodian_true;
  animation-fill-mode: forwards; /**让css keyframes动画停在最后一刻 */
}
@keyframes anim_pc_maodian_true {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-10px);
  }
  40% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
/**数据左右反复翻转动画 */
.anim_rotate_true_setting {
  animation-delay: 0s; /**延迟执行 */
  animation-duration: 3.5s; /**执行时间 */
  animation-name: anim_rotate_true;
}
@keyframes anim_rotate_true {
  from {
    /* transform: rotateY(0deg);  */
  }
  to {
    transform: rotateY(180deg) rotateY(180deg) rotateY(180deg) rotateY(180deg)
      rotateY(180deg) rotateY(180deg) rotateY(180deg) rotateY(180deg)
      rotateY(180deg) rotateY(180deg) rotateY(180deg) rotateY(180deg)
      rotateY(180deg) rotateY(180deg) rotateY(180deg) rotateY(180deg)
      rotateY(180deg) rotateY(180deg) rotateY(180deg) rotateY(180deg);
  }
}
/**淡入+位移动画 */
.anim_opacity_transform_true_setting {
  /* animation-delay: 0s;  */
  /* animation-delay: 0.3s;   */
  animation-delay: 0s;
  animation-duration: 2.5s; /**执行时间 */
  animation-name: anim_opacity_transform_true;
  // animation-fill-mode: forwards; /**让css keyframes动画停在最后一刻 */
}
@keyframes anim_opacity_transform_true {
  0% {
    opacity: 0;
    transform: translateY(180px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/**回弹动画 */
.anim_huitan_true_setting {
  animation-delay: 0s; /**延迟执行 */
  animation-duration: 1.5s; /**执行时间 */
  animation-name: anim_huitan_true;
}
@keyframes anim_huitan_true {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(30px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.ll_common_lianmeng_title {
  background: #d72929;
  width: 100%;
  max-width: 1280px;
  height: 36px;
  margin-left: 180px;
  margin-right: 180px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  color: #ffffff;
  font-weight: 300;
}
.tv_common_title_up {
  font-size: 50px;
  color: #333333;
  letter-spacing: 0.09px;
  font-weight: 300;
}
.line_common_title {
  width: 100%;
  height: 1px;
  margin-top: 5px;
  background: #f65d5d;
}
.tv_common_title_down {
  margin-top: 5px;
  font-size: 22px;
  color: #333333;
  font-weight: 300;
  margin-bottom: 100px;
}
.iv_content_1_2_1_child {
  margin-left: 50px;
  width: 229px;
  height: 70px;
}
.ll_content_1_2_1_child {
  margin-right: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.iv_maodian_2 {
  width: 26px;
  height: 25px;
  cursor: pointer;
}
.iv_maodian {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
}
.ll_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;

  .ll_xieyi_1 {
    z-index: 10;
    width: 100%;
    background: rgb(245, 245, 245);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ll_xieyi_4 {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .tv_xieyi_2 {
      margin-top: 15px;
      margin-left: 40px;
      margin-right: 40px;
      padding: 5px, 5px, 5px, 5px;
      font-size: 14px;
      color: rgb(254, 40, 75);
    }
    .tv_xieyi_5 {
      color: rgb(102, 102, 102);
      font-size: 14px;
    }
    .tv_xieyi_6 {
      color: rgb(0, 0, 0);
      font-size: 14px;
      margin-left: 20px;
      margin-right: 20px;
    }

    .ll_xieyi_7 {
      margin-top: 15px;
      margin-bottom: 15px;
      padding: 5px, 5px, 5px, 5px;
      color: rgb(0, 0, 0);
      margin-left: 20px;
      margin-right: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .iv_xieyi_8 {
      width: 20px;
      height: 20px;
    }
    .tv_xieyi_9 {
      color: #000000;
      font-size: 14px;
      margin-right: 20px;
    }
  }
  .ll_content_6 {
    padding-top: 100px;
    // padding-bottom: 100px;

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    .ll_content_6_child {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      // flex-wrap: wrap;
      .iv_content_6_child {
        width: 654px;
        height: 570px;
        margin-left: 20px;
        margin-right: 20px;
      }
      .tv_content_6_child {
        max-width: 442px;
        height: 393px;
        margin-top: 97px;
        margin-left: 20px;
        margin-right: 20px;
        font-weight: 300;
        text-align: justify;

        font-size: 16px;
        color: #363636;
        line-height: 40px;
      }
    }
  }
  .ll_content_5 {
    padding-top: 100px;
    padding-bottom: 100px;

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;

    background-image: url("../assets/images/ic_pc_bg_2.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .ll_content_5_chlid {
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: center;

      .ll_content_5_chlid_arr {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // flex-wrap: wrap;
        .ll_content_5_item {
          margin-top: 30px;
          width: 180px;
          height: 180px;
          background: #ffffff;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .tv_content_5_item {
            width: 153px;
            height: 53px;
            margin-top: 5px;
            font-size: 16px;
            color: #595757;
            font-weight: 300;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
  .ll_content_4 {
    padding-top: 100px;
    padding-bottom: 100px;

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;

    .ll_content_4_3 {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      // flex-wrap: wrap;
      .ll_content_4_3_1 {
        flex: 1;
        margin-left: 30px;
        margin-right: 30px;
        // width: 365px;
        max-width: 365px;
        min-height: 220px;

        padding-bottom: 40px;
        background: rgb(245, 245, 245);
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: flex-start;
        position: relative;
        text-align: left;

        .iv_youshi_6 {
          width: 42px;
          height: 49px;
          position: absolute;
          margin-top: 10px;
          margin-left: -12px;
          left: 0px;
          z-index: 5;
        }

        .tv_youshi_4 {
          font-size: 22px;
          color: #000000;
          font-weight: 300;
          margin-top: 20px;
          margin-left: 40px;
        }

        .tv_youshi_5 {
          font-weight: 300;
          margin-top: 20px;
          margin-left: 40px;
          margin-right: 40px;
          text-align: justify;

          font-size: 16px;
          color: #363636;
          line-height: 24px;
        }
      }
    }
  }
  .ll_content_3 {
    padding-top: 100px;
    padding-bottom: 100px;

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;

    background-image: url("../assets/images/ic_pc_gs.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .ll_content_3_3 { 
      width: 100%;
      max-width: 1300px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      // flex-wrap: wrap;

      .ll_content_3_3_1 {
        min-width: none;
        flex: 1;
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        /*scroll-view 横向滑动，需要设置成display: inline-block; 父布局要加white-space: nowrap;*/
        flex-direction: column;
        /*元素排列方向【row：横向排列】【column：纵向排列】*/
        justify-content: center;
        /*水平对齐方式【start：从左到右】【space-between：两端对齐】【center：居中】*/
        align-items: center;
        .tv_fupin_6_2_phone {
          font-size: 35px;
          color: #ffffff;
          font-weight: 300;
        }
        .tv_fupin_sanjiao_phone {
          margin-top: 20px;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid #f4f4f4;
        }
        .tv_fupin_6_3_phone {
          background-color: #f4f4f4;
          // width: 210px;
          width: 100%;
          max-width: 210px;
          display: flex;
          /*scroll-view 横向滑动，需要设置成display: inline-block; 父布局要加white-space: nowrap;*/
          flex-direction: column;
          /*元素排列方向【row：横向排列】【column：纵向排列】*/
          justify-content: center;
          /*水平对齐方式【start：从左到右】【space-between：两端对齐】【center：居中】*/
          align-items: center;
          /*垂直对方方式【flex-start：从上到下】【center：居中】*/
          padding-top: 10px;
          padding-bottom: 10px;

          font-size: 22px;
          color: #ee241e;
          font-weight: 300;
        }
      }
    }
  }
  .ll_content_2 {
    padding-top: 100px;
    padding-bottom: 100px;

    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    .tv_content_2_1 {
      margin-top: 150px;
      font-size: 50px;
      color: #333333;
      letter-spacing: 0.09px;
      font-weight: 300;
    }
    .ll_content_2_3 {
      width: 100vw;
      display: flex;
      /*scroll-view 横向滑动，需要设置成display: inline-block; 父布局要加white-space: nowrap;*/
      flex-direction: row;
      /*元素排列方向【row：横向排列】【column：纵向排列】*/
      justify-content: center;
      /*水平对齐方式【start：从左到右】【space-between：两端对齐】【center：居中】*/
      align-items: center;
      /*垂直对方方式【flex-start：从上到下】【center：居中】*/

      // flex-wrap: wrap;
      .ll_gongyi_5_1_phone { 
        display: flex;
        /*scroll-view 横向滑动，需要设置成display: inline-block; 父布局要加white-space: nowrap;*/
        flex-direction: column;
        /*元素排列方向【row：横向排列】【column：纵向排列】*/
        justify-content: center;
        /*水平对齐方式【start：从左到右】【space-between：两端对齐】【center：居中】*/
        align-items: center;
        /*垂直对方方式【flex-start：从上到下】【center：居中】*/
        .ll_gongyi_5_3_phone {
          width: 100px;
          height: 100px;
          display: flex;
          /*scroll-view 横向滑动，需要设置成display: inline-block; 父布局要加white-space: nowrap;*/
          flex-direction: column;
          /*元素排列方向【row：横向排列】【column：纵向排列】*/
          justify-content: center;
          /*水平对齐方式【start：从左到右】【space-between：两端对齐】【center：居中】*/
          align-items: center;
          /*垂直对方方式【flex-start：从上到下】【center：居中】*/
          border-radius: 50%;
          background: #fe284b;
          z-index: 10;
        }
        .ll_gongyi_6_phone {
          margin-top: -50px;
          // width: 276px;
          max-width: 276px;
          
          margin-left: 25px;
          margin-right: 25px;
          box-shadow: 0 4px 16px 4px hsla(0, 0%, 60%, 0.15);
          background: #ffffff;
          display: flex;
          /*scroll-view 横向滑动，需要设置成display: inline-block; 父布局要加white-space: nowrap;*/
          flex-direction: column;
          /*元素排列方向【row：横向排列】【column：纵向排列】*/
          justify-content: left;
          align-items: center;
          /*垂直对方方式【flex-start：从上到下】【center：居中】*/
          .tv_gongyi_6_1_phone {
            margin-top: 60px;

            font-size: 22px;
            color: #fe294b;
            font-weight: 300;
          }
          .v_gongyi_6_2_phone {
            width: 120px;
            height: 20px;
            border-bottom: solid 1px rgb(254, 40, 75);
          }
          .tv_gongyi_6_4_phone {
            margin-top: 30px;
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 50px;
            text-align: justify;

            font-size: 16px;
            color: #363636;
            line-height: 24px;
            font-weight: 300;
          }
        }
      }
    }
  }

  .ll_content_1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    // min-width: 1920px;
    min-height: 619px;
    height: 100vh;

    /**背景图 */
    background-image: url("../assets/images/ic_pc_1.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    .ll_content_1_2 {
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      /*scroll-view 横向滑动，需要设置成display: inline-block; 父布局要加white-space: nowrap;*/
      flex-direction: column;
      /*元素排列方向【row：横向排列】【column：纵向排列】*/
      justify-content: space-between;

      align-items: center;
      flex-wrap: wrap;
      .ll_content_1_2_2 {
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .ll_content_1_2_2_3 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 160px;
          .tv_content_1_2_2_3 {
            margin-top: 25px;
            font-size: 22px;
            color: #ffffff;
            line-height: 33px;
            font-weight: 300;
          }
        }
      }
      .ll_content_1_2_1 {
        width: 100%;
        margin-top: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .iv_content_1_2_1 {
          margin-left: 50px;
          width: 229px;
          height: 70px;
        }
        .ll_content_1_2_1_2 {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .iv_content_1_2_1_2 {
            width: 35px;
            height: 31px;
          }
          .tv_content_1_2_1_2 {
            font-size: 16px;
            margin-left: 8px;
            color: #ffffff;
            margin-bottom: 3px;
          }
        }
        .v_content_view_1 {
          border: 1px solid #ffffff;
          height: 20px;
          margin-left: 28px;
          margin-right: 34px;
        }

        .ll_content_1_2_1_3 {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .tv_content_1_2_1_3 {
            font-size: 16px;
            margin-left: 8px;
            color: #ffffff;
            margin-bottom: 3px;
            font-weight: 300;
          }
        }
      }
    }
  }
}
</style>