<template>
  <div class="ll_content">
    <!--模块1 主页   :style="'height:' + windowHeight + 'px'"-->
    <div v-if="true" class="ll_content_1">
      <!--内容居中-->
      <div class="ll_content_1_2_phone">
        <!--logo、中行动态、联系我们-->
        <div class="ll_content_1_2_1">
          <!--中行动态-->
          <div class="ll_content_1_2_1_2" @click="clickItem('zhong_hang')">
            <img
              class="iv_content_1_2_1_2"
              src="../assets/images/ic_pc_3.png"
            />
            <div class="tv_content_1_2_1_2">
              {{ pageData.zhonghangTitle }}
            </div>
          </div>
          <!--占位-->
          <div class="v_content_view_1"></div>
          <!--联系我们-->
          <div
            class="ll_content_1_2_1_3"
            @click="clickItem('callPhone', '4006667308')"
          >
            <img
              style="width: 0.37rem; height: 0.37rem"
              src="../assets/images/ic_pc_4.png"
            />
            <div class="tv_content_1_2_1_3">{{ pageData.lianxiInfo }}</div>
          </div>
        </div>
        <div style="margin-top: 50px"></div>
        <!--logo-->
        <img
          class="iv_content_1_2_phone_logo"
          src="../assets/images/ic_pc_2.png"
        />
        <div style="margin-top: 60px"></div>
        <!--大标题-->
        <img
          class="iv_content_1_2_big_phone_logo"
          src="../assets/images/ic_pc_5.png"
        />
        <!--为方法调试 间距写在这儿-->
        <div style="margin-top: 60px"></div>
        <!--三个按钮组-->
        <div class="ll_content_1_2_2_phone">
          <!--android-->
          <div imageType="android">
            <MyDialogDownloadInfo @clickItem="clickItem" clickType="android_download">
              <div class="ll_content_1_2_2_3">
                <img
                  style="width: 1.39rem; height: 1.39rem"
                  src="../assets/images/ic_pc_android.png"
                />
                <div class="tv_content_1_2_2_3">{{ pageData.download_1 }}</div>
              </div>
            </MyDialogDownloadInfo>
          </div>
          <!--ios-->
          <div imageType="ios">
            <MyDialogDownloadInfo @clickItem="clickItem" clickType="ios_download">
              <div
                class="ll_content_1_2_2_3" 
              >
                <img
                  style="width: 1.39rem; height: 1.39rem"
                  src="../assets/images/ic_pc_ios.png"
                />
                <div class="tv_content_1_2_2_3">{{ pageData.download_2 }}</div>
              </div>
            </MyDialogDownloadInfo>
          </div>
          <!--mini-->
          <div imageType="mini" @click="clickItem('mini_download')">
            <div class="ll_content_1_2_2_3">
              <img
                style="width: 1.39rem; height: 1.39rem"
                src="../assets/images/ic_pc_mini.png"
              />
              <div class="tv_content_1_2_2_3">{{ pageData.download_3 }}</div>
            </div>
          </div>
        </div>
        <!-- <div class="v_line"></div> -->
        <div style="margin-top: 50px"></div>
        <!--锚点按钮-->
        <img
          class="iv_maodian anim_pc_maodian_true_setting"
          src="../assets/images/ic_pc_start.png"
          @click="clickItem('gongyi')"
        />
        <div style="margin-top: 10px"></div>
        <!--锚点按钮-->
        <!-- <MyAnimationMaodian class="iv_maodian">
          <img
            src="../assets/images/ic_pc_start.png" 
            @click="clickItem('gongyi')"
          />
        </MyAnimationMaodian> -->
      </div>
    </div>
    <!--模块2 公益-->
    <div v-if="true" class="ll_content_2" id="gongyi">
      <!--标题-->
      <div class="tv_common_title_up">
        {{ pageData.gongyiTitle }}
        <div class="line_common_title"></div>
      </div>

      <div class="tv_common_title_down">{{ pageData.gongyiTitleDesc }}</div>
      <!--内容块-->
      <div class="ll_content_2_3" ref="isShowOPTRAnim">
        <div
          :class="{
            ll_gongyi_5_1_phone: true,
            anim_opacity_transform_true_setting: isShowOPTRAnim,
          }"
          v-for="item in pageData.gongyiData"
          :key="item.message"
        >
          <div class="ll_gongyi_5_3_phone">
            <img
              style="width: 1rem; height: 1rem"
              class="iv_gongyi_5_2_phone"
              :src="item.logo"
            />
          </div>
          <div class="ll_gongyi_6_phone">
            <div class="tv_gongyi_6_1_phone">{{ item.name }}</div>
            <div class="v_gongyi_6_2_phone"></div>
            <div class="tv_gongyi_6_4_phone">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
    <!--模块3 帮扶-->
    <div v-if="true" class="ll_content_3">
      <!--背景图-->

      <!--标题-->
      <div class="tv_common_title_up">
        {{ pageData.bangfuTitle }}
        <div class="line_common_title"></div>
      </div>

      <div class="tv_common_title_down">
        {{ pageData.bangfuTitleDesc }}
      </div>
      <!--内容块-->
      <div class="ll_content_3_3" ref="isShowRotateAnim">
        <div
          class="ll_content_3_3_1"
          v-for="(item, index) in pageData.bangfuData"
          :key="index"
        >
          <div
            :class="{
              tv_fupin_6_2_phone: true,
              anim_rotate_true_setting: isShowRotateAnim,
            }"
          >
            {{ item.name }}
          </div>
          <div class="tv_fupin_sanjiao_phone"></div>
          <div class="tv_fupin_6_3_phone">{{ item.desc }}</div>
        </div>
      </div>
      <!--提示-->
      <div
        style="
          font-size: 0.22rem;
          color: #ffffff;
          font-weight: 300;
          margin-top: 1rem;
        "
      >
        {{ pageData.bangfuDesc }}
      </div>
    </div>
    <!--模块4 平台优势-->
    <div v-if="true" class="ll_content_4">
      <!--标题-->
      <div class="tv_common_title_up">
        {{ pageData.pingtaiTitle }}
        <div class="line_common_title"></div>
      </div>

      <div class="tv_common_title_down">
        {{ pageData.pingtaiTitleDesc }}
      </div>
      <!--内容块-->
      <div class="ll_content_4_3" ref="isShowHuiTanAnim">
        <div
          v-for="(item, index) in pageData.pingtaiData"
          :key="index"
          :class="{
            ll_content_4_3_1: true,
            ll_content_4_3_1_top: index != 0,
            anim_huitan_true_setting: isShowHuiTanAnim,
          }"
        >
          <img class="iv_youshi_6" :src="item.logo" />
          <div class="tv_youshi_4">{{ item.name }}</div>
          <div class="tv_youshi_5">
            {{ item.desc }}
          </div>
        </div>
      </div>
    </div>
    <!--模块5 公益联盟-->
    <div v-if="true" class="ll_content_5">
      <!--标题-->
      <div class="tv_common_title_up">
        {{ pageData.lianmengTitle }}
        <div class="line_common_title"></div>
      </div>

      <div class="tv_common_title_down">
        {{ pageData.lianmengTitleDesc }}
      </div>

      <!--公益联盟内容列表-->
      <div
        :class="{
          ll_content_5_chlid: true,
        }"
      >
        <div class="tv_lianmengData_title">
          {{ pageData.lianmengData_1_title }}
        </div>
        <!--合作联盟数组-->
        <div class="ll_content_5_chlid_arr">
          <div
            class="ll_content_5_item"
            :style="
              'margin-left:0.23rem;' +
              'margin-right:0.23rem;' +
              (index == pageData.lianmengData_1.length - 1
                ? 'background:rgba(255,255,255,0.60);'
                : '')
            "
            v-for="(item, index) in pageData.lianmengData_1"
            :key="index"
          >
            <img style="width: 100%; height: 100%" :src="item.logo_big" />
            <!-- <img
              style="width: 0.88rem; height: 0.88rem"
              :src="item.logo"
              v-if="index != pageData.lianmengData_1.length - 1"
            />
            <div
              class="tv_content_5_item"
              v-if="index != pageData.lianmengData_1.length - 1"
            >
              {{ item.name }}
            </div> 
            <img
              style="width: 0.68rem; height: 0.08rem"
              :src="item.logo"
              v-if="index == pageData.lianmengData_1.length - 1"
            /> -->
          </div>
        </div>

        <div class="tv_lianmengData_title" style="margin-top: 1rem">
          {{ pageData.lianmengData_2_title }}
        </div>
        <!--合作联盟数组-->
        <div class="ll_content_5_chlid_arr">
          <div
            class="ll_content_5_item"
            :style="
              'margin-left:0.23rem;' +
              'margin-right:0.23rem;' +
              (index == pageData.lianmengData_2.length - 1
                ? 'background:rgba(255,255,255,0.60);'
                : '')
            "
            v-for="(item, index) in pageData.lianmengData_2"
            :key="index"
          >
            <img style="width: 100%; height: 100%" :src="item.logo_big" />
            <!--前5个样式-->
            <!-- <img
              style="width: 0.88rem; height: 0.88rem"
              :src="item.logo"
              v-if="index != pageData.lianmengData_2.length - 1"
            />
            <div
              class="tv_content_5_item"
              v-if="index != pageData.lianmengData_2.length - 1"
            >
              {{ item.name }}
            </div> -->
            <!--最后一个样式-->
            <!-- <img
              style="width: 0.68rem; height: 0.08rem"
              :src="item.logo"
              v-if="index == pageData.lianmengData_2.length - 1"
            /> -->
          </div>
        </div>
      </div>
    </div>
    <!--模块6 关于我们-->
    <div v-if="true" class="ll_content_6">
      <!--标题-->
      <div class="tv_common_title_up">
        {{ pageData.aboutTitle }}
        <div class="line_common_title"></div>
      </div>

      <div class="tv_common_title_down">
        {{ pageData.aboutTitleDesc }}
      </div>
      <!--关于我们内容-->
      <div class="ll_content_6_child">
        <div class="tv_content_6_child">{{ pageData.aobutDesc }}</div>
        <img class="iv_content_6_child" src="../assets/images/ic_shouchi.jpg" />
      </div>
    </div>
    <!--底部协议-->
    <div v-if="true" class="ll_xieyi_1">
      <!--占位-->
      <div style="margin-bottom: 0.2rem"></div>
      <div class="ll_xieyi_4">
        <div class="tv_xieyi_2" @click="clickItem('agreement', 310)">
          {{ pageData.agreement_1 }}
        </div>
        <div class="tv_xieyi_2" @click="clickItem('agreement', 100)">
          {{ pageData.agreement_2 }}
        </div>
      </div>
      <!--占位-->
      <div style="margin-bottom: 0.2rem"></div>
      <div class="ll_xieyi_4">
        <div class="tv_xieyi_5">{{ pageData.company_1 }}</div>
        <div style="width: 0.2rem"></div>
        <div class="tv_xieyi_5" @click="clickItem('callPhone', '4006667308')">
          {{ pageData.bottom_info }}
        </div>
      </div>
      <!--占位-->
      <div style="margin-bottom: 0.2rem"></div>
      <div class="ll_xieyi_4">
        <div class="tv_xieyi_6" @click="clickItem('beian_icp')">
          {{ pageData.ICP_info }}
        </div>
        <div class="ll_xieyi_7" @click="clickItem('beian_gov')">
          <img class="iv_xieyi_8" src="../assets/images/gongwanganbeihao.png" />
          <div class="tv_xieyi_9">{{ pageData.beian_info }}</div>
        </div>
      </div>
      <!--占位-->
      <div style="margin-bottom: 0.2rem"></div>
    </div>
  </div>
</template>

<script >
//指引
import MyDialogDownloadInfo from "./my-dialog-download-info.vue";
export default {
  name: "MyPagePc",
  props: ["pageData"],
  data() {
    return {
      isShowRotateAnim: false, //显示回滚动画
      isShowOPTRAnim: false, //淡入＋位移动画
      isShowHuiTanAnim: false, //回弹动画
    };
  },
  components: {
    MyDialogDownloadInfo,
    // MyAnimationMaodian,
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); //页面滚动监听
  },
  methods: {
    showAnim(refName) {
      if (
        !this[refName] &&
        this.$refs[refName] &&
        this.$refs[refName].getBoundingClientRect().y < window.innerHeight
      ) {
        //显示动画
        this[refName] = true;
      }
    },
    handleScroll() {
      //根据页面滚动状态，对页面的动画进行相应的显示操作
      //淡入+位移
      this.showAnim("isShowOPTRAnim");
      //帮扶数据左右旋转动画
      // this.showAnim("isShowRotateAnim");
      //回弹动画
      this.showAnim("isShowHuiTanAnim");

      // this.$refs.refBangfu.getBoundingClientRect()

      // for (var i = 0; i < this.animArr.length; i++) {
      //   var item = this.animArr[i];
      //   if (this.$refs[item.name]) {
      //     //存在实例
      //     if (
      //       this.$refs[item.name].getBoundingClientRect().y < window.innerHeight
      //     ) {
      //       this.$refs[item.name].classList.add(item.show);
      //     } else {
      //       // that.$refs[item.name].classList.remove(item.show);
      //     }
      //   }
      // }
    },
    clickItem(type, value) {
      this.$emit("appOpen", type, value);
    },
  },
};
</script>

<style lang="scss" scoped>
/**直接用手机端 上下移动动画 */
.anim_pc_maodian_true_setting {
  animation: shake 1.2s infinite ease-in-out;
  animation-name: anim_pc_maodian_true;
  animation-fill-mode: forwards; /**让css keyframes动画停在最后一刻 */
}
@keyframes anim_pc_maodian_true {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-10px);
  }
  40% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
/**数据左右反复翻转动画 */
.anim_rotate_true_setting {
  animation-delay: 0s; /**延迟执行 */
  animation-duration: 3.5s; /**执行时间 */
  animation-name: anim_rotate_true;
}
@keyframes anim_rotate_true {
  from {
    /* transform: rotateY(0deg);  */
  }
  to {
    transform: rotateY(180deg) rotateY(180deg) rotateY(180deg) rotateY(180deg)
      rotateY(180deg) rotateY(180deg) rotateY(180deg) rotateY(180deg)
      rotateY(180deg) rotateY(180deg) rotateY(180deg) rotateY(180deg)
      rotateY(180deg) rotateY(180deg) rotateY(180deg) rotateY(180deg)
      rotateY(180deg) rotateY(180deg) rotateY(180deg) rotateY(180deg);
  }
}
/**淡入+位移动画 */
.anim_opacity_transform_true_setting {
  /* animation-delay: 0s;  */
  /* animation-delay: 0.3s;   */
  animation-delay: 0s;
  animation-duration: 2.5s; /**执行时间 */
  animation-name: anim_opacity_transform_true;
  // animation-fill-mode: forwards; /**让css keyframes动画停在最后一刻 */
}
@keyframes anim_opacity_transform_true {
  0% {
    opacity: 0;
    transform: translateY(180px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/**回弹动画 */
.anim_huitan_true_setting {
  animation-delay: 0s; /**延迟执行 */
  animation-duration: 1.5s; /**执行时间 */
  animation-name: anim_huitan_true;
}
@keyframes anim_huitan_true {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(30px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
.tv_common_title_up {
  font-size: 0.5rem;
  color: #333333;
  font-weight: 300;
}
.line_common_title {
  width: 100%;
  height: 1px;
  margin-top: 0.05rem;
  background: #f65d5d;
}
.tv_common_title_down {
  margin-bottom: 1rem;
  color: #333333;
  font-size: 0.22rem;
  font-weight: 600;
}

.iv_maodian {
  bottom: 0rem;
  width: 0.52rem;
  height: 0.5rem;
  // margin-top: 2.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.tv_lianmengData_title {
  width: 6.34rem;
  height: 0.436rem;
  background: #aa1e2b;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 0.22rem;
  color: #ffffff;
  font-weight: 300;
}
.ll_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;

  .ll_xieyi_1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    margin-bottom: env(safe-area-inset-bottom);
    /* 兼容 iOS >= 11.2  当网页设置viewport-fit=cover的时候才生效。*/
    // background: #0003;
    background: rgb(245, 245, 245);
    .ll_xieyi_4 {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .tv_xieyi_2 {
        padding-left: 0.4rem;
        padding-right: 0.4rem;
        font-size: 0.16rem;
        color: rgb(254, 40, 75);
        font-weight: 300;
      }
      .tv_xieyi_5 {
        color: rgb(102, 102, 102);
        font-size: 0.16rem;
        font-weight: 300;
      }
      .tv_xieyi_6 {
        color: rgb(0, 0, 0);
        font-size: 0.16rem;
        font-weight: 300;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
      }
      .ll_xieyi_7 {
        color: rgb(0, 0, 0);
        font-size: 0.16rem;
        font-weight: 300;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .iv_xieyi_8 {
          width: 0.2rem;
          height: 0.2rem;
        }
        .tv_xieyi_9 {
          color: #000000;
          font-size: 0.16rem;
          font-weight: 300;
          padding-right: 0.2rem;
        }
      }
    }
  }
  .ll_content_6 {
    padding-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    .ll_content_6_child {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      .iv_content_6_child {
        margin-top: 1rem;
        width: 6.54rem;
        height: 5.7rem;
        margin-left: 0.1rem;
        margin-right: 0.1rem;
      }
      .tv_content_6_child {
        width: 5rem;
        // height: 3.93rem;
        margin-left: 0.1rem;
        margin-right: 0.1rem;
        font-weight: 300;
        // text-align: left;
        text-align: justify;

        font-size: 0.22rem;
        color: #363636;
        line-height: 0.4rem;
      }
    }
  }
  .ll_content_5 {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;

    background-image: url("../assets/images/ic_iphone_bg_2.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .ll_content_5_chlid {
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: center;

      .ll_content_5_chlid_arr {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .ll_content_5_item {
          margin-top: 0.3rem;
          width: 1.8rem;
          height: 1.8rem;
          background: #ffffff;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .tv_content_5_item {
            margin-top: 0.05rem;
            width: 1.55rem;
            height: 0.53rem;
            margin-top: 0.05rem;

            color: #595757;
            font-weight: 300;

            font-size: 0.22rem;
            line-height: 0.24rem;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
  .ll_content_4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;

    .ll_content_4_3 {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .ll_content_4_3_1_top {
        margin-top: 0.3rem;
      }
      .ll_content_4_3_1 {
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        width: 6.5rem;
        height: 2.84rem;
        background: rgb(245, 245, 245);
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: flex-start;
        position: relative;
        text-align: left;

        .iv_youshi_6 {
          width: 0.42rem;
          height: 0.49rem;
          position: absolute;
          margin-top: 0.1rem;
          margin-left: -0.12rem;
          left: 0px;
          z-index: 5;
        }

        .tv_youshi_4 {
          font-size: 0.22rem;
          font-weight: 600;
          color: #000000;
          margin-top: 0.2rem;
          margin-left: 0.4rem;
        }

        .tv_youshi_5 {
          margin-top: 0.2rem;
          margin-left: 0.4rem;
          margin-right: 0.4rem;
          text-align: justify;

          font-size: 0.22rem;
          font-weight: 300;
          color: #363636;
          line-height: 0.4rem;
        }
      }
    }
  }
  .ll_content_3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;

    background-image: url("../assets/images/ic_iphone_gs.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .ll_content_3_3 {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .ll_content_3_3_1 {
        margin-bottom: 0.4rem;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        width: 2.7rem;
        display: flex;
        /*scroll-view 横向滑动，需要设置成display: inline-block; 父布局要加white-space: nowrap;*/
        flex-direction: column;
        /*元素排列方向【row：横向排列】【column：纵向排列】*/
        justify-content: center;
        /*水平对齐方式【start：从左到右】【space-between：两端对齐】【center：居中】*/
        align-items: center;
        .tv_fupin_6_2_phone {
          font-size: 0.35rem;
          color: #ffffff;
          font-weight: 300;
        }
        .tv_fupin_sanjiao_phone {
          margin-top: 0.2rem;
          width: 0;
          height: 0;
          border-left: 0.1rem solid transparent;
          border-right: 0.1rem solid transparent;
          border-bottom: 0.1rem solid #f4f4f4;
        }
        .tv_fupin_6_3_phone {
          background-color: #f4f4f4;
          width: 2.1rem;
          display: flex;
          /*scroll-view 横向滑动，需要设置成display: inline-block; 父布局要加white-space: nowrap;*/
          flex-direction: column;
          /*元素排列方向【row：横向排列】【column：纵向排列】*/
          justify-content: center;
          /*水平对齐方式【start：从左到右】【space-between：两端对齐】【center：居中】*/
          align-items: center;
          /*垂直对方方式【flex-start：从上到下】【center：居中】*/
          padding-top: 0.1rem;
          padding-bottom: 0.1rem;

          color: #ee241e;
          font-size: 0.22rem;
          font-weight: 600;
        }
      }
    }
  }
  .ll_content_2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    .ll_content_2_3 {
      width: 100%;
      display: flex;
      /*scroll-view 横向滑动，需要设置成display: inline-block; 父布局要加white-space: nowrap;*/
      flex-direction: row;
      /*元素排列方向【row：横向排列】【column：纵向排列】*/
      justify-content: center;
      /*水平对齐方式【start：从左到右】【space-between：两端对齐】【center：居中】*/
      align-items: center;
      /*垂直对方方式【flex-start：从上到下】【center：居中】*/
      flex-wrap: wrap;
      .ll_gongyi_5_1_phone {
        margin-top: 0.3rem;
        display: flex;
        /*scroll-view 横向滑动，需要设置成display: inline-block; 父布局要加white-space: nowrap;*/
        flex-direction: column;
        /*元素排列方向【row：横向排列】【column：纵向排列】*/
        justify-content: center;
        /*水平对齐方式【start：从左到右】【space-between：两端对齐】【center：居中】*/
        align-items: center;
        /*垂直对方方式【flex-start：从上到下】【center：居中】*/
        .ll_gongyi_5_3_phone {
          width: 1rem;
          height: 1rem;
          display: flex;
          /*scroll-view 横向滑动，需要设置成display: inline-block; 父布局要加white-space: nowrap;*/
          flex-direction: column;
          /*元素排列方向【row：横向排列】【column：纵向排列】*/
          justify-content: center;
          /*水平对齐方式【start：从左到右】【space-between：两端对齐】【center：居中】*/
          align-items: center;
          /*垂直对方方式【flex-start：从上到下】【center：居中】*/
          border-radius: 50%;
          background: #fe284b;
          z-index: 0;
        }
        .ll_gongyi_6_phone {
          margin-top: -0.5rem;
          width: 2.96rem;
          min-height: 3.5rem;
          margin-left: 0.25rem;
          margin-right: 0.25rem;
          box-shadow: 0 4px 16px 4px hsla(0, 0%, 60%, 0.15);
          background: #ffffff;
          display: flex;
          /*scroll-view 横向滑动，需要设置成display: inline-block; 父布局要加white-space: nowrap;*/
          flex-direction: column;
          /*元素排列方向【row：横向排列】【column：纵向排列】*/
          justify-content: left;
          align-items: center;
          /*垂直对方方式【flex-start：从上到下】【center：居中】*/
          .tv_gongyi_6_1_phone {
            margin-top: 0.6rem;

            color: #fe294b;
            font-size: 0.22rem;
            font-weight: 600;
          }
          .v_gongyi_6_2_phone {
            width: 0.95rem;
            margin-top: 5px;
            height: 1px;
            border-bottom: solid 1px rgb(254, 40, 75);
          }
          .tv_gongyi_6_4_phone {
            margin-top: 0.3rem;
            margin-left: 0.2rem;
            margin-right: 0.2rem;
            margin-bottom: 0.5rem;
            text-align: justify;

            font-size: 0.22rem;
            color: #363636;
            line-height: 0.4rem;
            font-weight: 300;
          }
        }
      }
    }
  }
  .ll_content_1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    min-height: 8rem;

    /**背景图 */
    background-image: url("../assets/images/ic_iphone_1.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    .ll_content_1_2_phone {
      z-index: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      align-items: center;
      .v_line {
        flex: 1;
        height: 0px;
      }
      .ll_content_1_2_2_phone {
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .ll_content_1_2_2_3 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 2rem;
          .tv_content_1_2_2_3 {
            margin-top: 0.25rem;
            color: #ffffff;
            line-height: 0.33rem;
            font-size: 0.22rem;
            font-weight: 600;
          }
        }
      }
      .iv_content_1_2_big_phone_logo {
        width: 5.5rem;
        height: 1.57rem;
      }
      .iv_content_1_2_phone_logo {
        width: 3rem;
        height: 0.91rem;
      }
      .ll_content_1_2_1 {
        margin-top: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .iv_content_1_2_1 {
          width: 3rem;
          height: 0.91rem;
        }
        .ll_content_1_2_1_2 {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .iv_content_1_2_1_2 {
            width: 0.46rem;
            height: 0.41rem;
          }
          .tv_content_1_2_1_2 {
            font-size: 0.22rem;
            margin-left: 0.08rem;
            color: #ffffff;
            margin-bottom: 0.05rem;
            font-weight: 300;
          }
        }
        .v_content_view_1 {
          border: 1px solid #ffffff;
          height: 0.2rem;
          margin-left: 0.28rem;
          margin-right: 0.34rem;
        }

        .ll_content_1_2_1_3 {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .tv_content_1_2_1_3 {
            font-size: 0.22rem;
            margin-left: 0.08rem;
            color: #ffffff;
            margin-bottom: 0.05rem;
            font-weight: 300;
          }
        }
      }
    }
  }
}
</style>